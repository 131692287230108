<template>
  <b-row>
    <b-col
      md="6"
      class="p-4 mx-auto"
    >
      <b-card>
        <b-card-header
          class="pl-0 pt-0 "
          style="display:inline !important"
        >
          <b-card-title class="text-center text-primary">
            <vuexy-logo />

          </b-card-title>
        </b-card-header>
        <p
          v-if="tryingLogin"
          style="text-align: center !important"
        >
          Welcome
        </p>
        <p
          v-else
          style="text-align: center !important"
          class="text-danger"
        >
          Oops!! We couldn't verify you
        </p>
        <p
          v-if="tryingLogin"
          style="text-align: center !important"
        >
          <b-spinner :show="isProcessing" />Please wait while loggin you into your Workzone...
        </p>
        <p
          v-else
          style="text-align: center !important"
        >
          Please contact the adminstrator for the further support.
        </p>
        <div class="text-center">
          <b-button
            variant="primary"
            class="mb-2 btn-sm-block mt-1"
            :to="{path:'/'}"
            :disabled="tryingLogin"
          >
            Back to home
          </b-button>
        </div>

      </b-card>

    </b-col>
  </b-row>
</template>
<script>
import {
  VBModal, BForm, BFormGroup, BFormInput, BSpinner, BButton,
  BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import VuexyLogo from '@core/layouts/components/Logo.vue'

import useApollo from '@/plugins/graphql/useApollo'

const defaultForm = () => ({
  name: '',

  email: '',

})
export default {
  components: {
    BFormInput,
    BFormGroup,
    BCard,
    BSpinner,
    BButton,
    BCardHeader,
    BCardTitle,
    VuexyLogo,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      // email,
      title: 'Invitation',
      isProcessing: false,
      form: {
        ...defaultForm(),
      },
      mode: 'verify',
      tryingLogin: true,
      token: this.$route.query.token,
      email: this.$route.query.email,
    }
  },
  mounted() {
    // localStorage.removeItem('userData')
    // localStorage.removeItem('accessToken')
    localStorage.clear()
    this.isProcessing = true
    useApollo.auth.verifyInvitation({
      token: this.token,
      email: this.email,
    })
      .then(response => {
        const userData = response.data.verifyInvitation
        localStorage.setItem('userData', JSON.stringify(userData.user))
        localStorage.setItem('accessToken', userData.access_token)
        // useJwt.setToken(userData.access_token)

        this.$router.replace({ name: 'home' }).then(() => {
          // this.$store.dispatch('authEvents/onUserLogin')
          this.showSuccessMessage({
            data: {
              message: `Welcome ${userData.name}, you are now logged in`,
            },
          })
        })
      }).catch(error => {
        this.showError(error.toString())
        this.tryingLogin = false
        this.isProcessing = false
      })
      .finally(() => {
        this.isProcessing = false
      })
  },
  methods: {
    handleOk() {
      // bvModalEvt.preventDefault()

    },

  },
}
</script>
